import { getField, updateField } from 'vuex-map-fields'
import { uri, prepareUrl } from '~/api/uri'

export const initState = {
  convertKey: 0,
  convert: {}
}
export const state = () => {
  return {
    ...initState
  }
}
export const actions = {
  async transform (state, { id, action }) {
    await this.$axios.post(prepareUrl(uri.media.transform, { id, action }))
  },
  async add ({ commit }, { id, file }) {
    const fd = new FormData()
    fd.append('file', file)
    const res = await this.$axios.post(prepareUrl(uri.media.add, { id }), fd)
      .catch((e) => {
        commit('setError', {
          title: 'Media upload error',
          message: e.message
        }, { root: true })
      })
    if (res?.data?.id && res?.data?.type === 'video') {
      this.$media.watch(res?.data?.id)
    }
  },
  async addLarge ({ commit, state }, { id, file }) {
    const size = 1024 * 1000
    const chunks = Math.ceil(file.size / size)
    const chanked = []
    for (let i = 0; i < chunks; i++) {
      chanked.push(file.slice(
        i * size, Math.min(i * size + size, file.size), file.type
      ))
    }
    if (chanked.length === 0) {
      return
    }

    let i = 0
    const t = chanked.length
    while (chanked.length > 0) {
      const form = new FormData()
      form.append('chunk', i)
      form.append('chunks', t)
      form.append('file', chanked.shift(), file.name)
      await this.$axios.post(
        prepareUrl(uri.media.addLarge, { id }),
        form
      )
        .then((res) => {
          if (res?.data?.id && res?.data?.type === 'video') {
            // eslint-disable-next-line no-console
            console.log('VIDEO UPLOADED', res.data.id, this.$media)
            this.$media.watch(res?.data?.id)
          }
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error(e.response)
          commit('setError', {
            title: 'Media upload error',
            message: e.message
          }, { root: true })
        })
      i++
    }
  },
  async update (state, { id, data }) {
    await this.$axios.post(prepareUrl(uri.media.update, { id }), data)
  },
  async delete (state, { id, data }) {
    await this.$axios.delete(prepareUrl(uri.media.delete, { id }), data)
  },
  async validate (state, { id, data, onSuccess, onError }) {
    const res = await this.$axios.post(prepareUrl(uri.media.validate, { id }), data)
    if (typeof res?.data?.result !== 'undefined') {
      if (!!res.data.result && typeof onSuccess === 'function') {
        onSuccess()
      } else if (!res.data.result && typeof onError === 'function') {
        onError()
      }
    }
  },
  async validateAll (state, { id }) {
    await this.$axios.post(prepareUrl(uri.media.validateAll, { id }))
  },
  async addAvatar (state, { mediaId, file }) {
    const fd = new FormData()
    fd.append('file', file)
    await this.$axios.post(prepareUrl(uri.media.addAvatar, { id: mediaId }), fd)
  },
  convertProgress ({ commit }, info) {
    commit('convertProgress', info)
  }
}
export const mutations = {
  updateField,
  convertProgress (state, info) {
    const c = state.convert
    c[info.id] = info
    state.convert = c
    state.convertKey += 1
    if (info.done) {
      state.convertKey = 0
    }
  },
  addChunk (state, ch) {
    state.chunks.push(ch)
  },
  shiftChunk (state) {
    state.chunks.shift()
  }
}
export const getters = {
  getField,
  getProgressKey (state) {
    return state.convertKey
  },
  getProgress (state) {
    return (id) => {
      if (state.convert[id]) {
        const r = state.convert[id]
        delete r?.id
        delete r?.name
        return r
      }
    }
  }
}
